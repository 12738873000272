<template>
  <!-- 我的收藏页面 -->
  <div class="BGS">
    <div class="List" v-for="(item,index) in List" :key="index" @click="Go_Details(item.cid)">
      <div class="List_top">
        <div class="List_top_L">{{item.complaintCollection.statement_title}}</div>
        <div class="List_top_R" v-if="item.complaintCollection.status ==2">已完成</div>
        <div class="List_top_R" v-if="item.complaintCollection.status ==5">未通过</div>
        <div class="List_top_R" v-if="item.complaintCollection.status ==1">处理中</div>
        <div class="List_top_R" v-if="item.complaintCollection.status ==0">审核中</div>
        <div class="List_top_R" v-if="item.complaintCollection.status ==4">已撤销</div>
      </div>
      <div class="List_content">
        <span>[投诉对象]</span>
        <span>{{item.complaintCollection.business_name}}</span>
      </div>
      <div class="List_content1">
        <span>[投诉理由]</span>
        <span>{{item.complaintCollection.complaint_reason}}</span>
      </div>
      <div class="List_bottom">
        <div class="List_bottom_L">
          <img :src="item.complaintCollection.headimgurl" alt="">
          <div class="name">{{item.complaintCollection.nickname}}</div>
          <div class="data">{{item.complaintCollection.create_time}}</div>
        </div>
        <div class="List_bottom_R">
          <div>
            <img src="@/assets/img/dianzan_icon.png" alt="">
            <span>{{item.complaintCollection.like_num}}</span>
          </div>
          <div>
            <img src="@/assets/img/pinglun_icon.png" alt="">
            <span>{{item.complaintCollection.comment_num}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection_list } from "../../api/api";
export default {
   metaInfo(){
     return{
        title: '我的收藏',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      List: [],
    };
  },
  methods: {
        // 跳转详情
    Go_Details(id){
      //console.log(id);
              this.$router.push({ path: "/PersonalCenter/CDetails",query:{id:id}
      });
    }
  },
  mounted() {
    collection_list({}).then((res) => {
      console.log(res);
      this.List = res.data.data.data;
    });
  },

};
</script>

<style scoped>
.BGS {
  width: 960px;
}
.List {
  padding: 20px;
  cursor: pointer;
  background: #fff;
  margin-left: 20px;
}
.List_top {
  display: flex;
  justify-content: space-between;
}
.List_top_L {
  width: 600px;
  font-size: 22px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.List_top_R {
  width: 60px;
  height: 22px;
  background: #d2e3f7;
  color: #1f72d9;
  text-align: center;
  line-height: 22px;
  border-radius: 4px;
  margin-right: 80px;
}
.List_content {
  margin-top: 15px;
}
.List_content span:nth-child(1) {
  color: #1f72d9;
  margin-right: 5px;
}
.List_content1 {
  margin-top: 8px;
  width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.List_content1 span:nth-child(1) {
  color: #1f72d9;
  margin-right: 5px;
}
.List_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.List_bottom_L {
  display: flex;
  align-items: center;
}
.List_bottom_L img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius:50%;
}
.List_bottom_L .name {
  margin-right: 25px;
}
.List_bottom_L .data {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
}
.List_bottom_R {
  margin-right: 80px;
  display: flex;
}

.List_bottom_R div {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.List_bottom_R img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>